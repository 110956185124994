jQuery(document).ready(function($) {
	// Seleziona il bottone "Tutti i progetti" e aggiungi la classe 'active'
	jQuery('.cta_azioni:contains("Tutti i progetti")').addClass('active');

	function loadProjects(term) {
		jQuery.ajax({
			url: handle_ajax_vars.ajaxurl,
			type: 'post',
			data: {
				action: 'filter_posts_by_term',
				term: term
			},
			success: function(result) {
				// Aggiorna il contenuto del tuo container con i nuovi post
				jQuery('#project_template #post_container').html(result);
	
				// Gestione delle animazioni e del focus per i dispositivi mobile
				if (window.matchMedia("(max-width: 768px)").matches) {
          // Seleziona tutti i project_container
					document.querySelectorAll(".project_container").forEach((container, index) => {
						// Identifica se il container ha una GIF
						const gif = container.querySelector('.gif_container');
						if(gif){
							// Se ha una GIF, imposta l'opacità iniziale a 0
							gsap.set(gif, {opacity: 0});
						}
	
						// Applica ScrollTrigger sia ai container con GIF che senza
						ScrollTrigger.create({
							trigger: container,
							start: "top center",
							end: "bottom center",
							onEnter: () => handleEnter(container, gif),
							onLeave: () => handleLeave(container, gif),
							onEnterBack: () => handleEnter(container, gif),
							onLeaveBack: () => handleLeave(container, gif),
						});
					});
				} else {
					if ($('#projects').length) {
						ScrollTrigger.create({
							trigger: '#projects .circle_container',
							scrub: true,
							pin: true,
							start: 'center center',
							endTrigger: '#projects',
							end: 'bottom bottom',
							// markers: true,
						})
						
						// Configurazione delle animazioni per desktop
						document.querySelectorAll("#projects .project_container.even").forEach((box, index) => {
							gsap.to(box, {
								yPercent: -20,
								ease: 'expo.out',
								scrollTrigger: {
									trigger: box,
									scrub: true,
									// markers: true,
								},
							});
						});
		
						document.querySelectorAll("#projects .project_container.odd").forEach((dx_box, index) => {
							gsap.to(dx_box, {
								yPercent: 15,
								ease: "expo.out",
								scrollTrigger: {
									trigger: dx_box,
									scrub: true
								},
							});
						});
					}

					if ($('#project_template').length) {
						let projects = Array.from(document.querySelectorAll("#project_template .project_container"))

						projects.forEach((box, index) => {
							if (index==0) return //skip first

							ScrollTrigger.create({
								trigger: box,
								start: 'top center',
								end: 'bottom center',
								// markers: true,
								onEnter() {
									$('.info.active').removeClass('active')
									$(`#info_${index}`).addClass('active')
								},
								onLeaveBack() {
									$('.info.active').removeClass('active')
									$(`#info_${index-1}`).addClass('active')
								},
							})
						});

						ScrollTrigger.create({
							trigger: '#project_template .info_container',
							pin: true,
							start: 'center center',
							endTrigger: '#project_template .info_helper',
							end: 'center center',
							// markers: true,
						})

						let project_clicked = false

						//Project detail animations
						$('#project_template .project_container').on('click', async function(e) {
							e.preventDefault()

							if (project_clicked) return
							
							project_clicked = true
							history.replaceState({}, "", `#${this.id}`);

							await setupDetailPage(this)

							$(`#${this.id} .image-container`).addClass('active')
							let preojectTl = gsap.timeline()
								.to(`#${this.id} .image-container`, {
									width: '100%',
									height: '100vh',
									duration: 1,
								})
								.to(`#${this.id} .image-container .image`, {
									width: '100%',
									height: '100%',
									duration: 1,
								}, '<')

							$("html, body").animate(
								{ scrollTop: $(`#${this.id}`).offset().top },
								1000,
								()=>setTimeout(()=>openDetailPage(this, preojectTl), 100)
							);
						})

						async function setupDetailPage(el) {
							let proj_id = $(el).attr('data-id')
							let proj_data = await getProjectById(proj_id)
							console.log(proj_data);

							$('#project_detail .detail_main_image').attr('src', proj_data.acf.immagine_progetto.url)
							$('#project_detail .title').text(proj_data.title.rendered)
							$('#project_detail .header .info .text .place').text(proj_data.acf.luogo_progetto)
							$('#project_detail .header .info .text .year').text(proj_data.acf.anno_progetto)
							$('#project_detail .header .info .text .actions').text('')
							proj_data.acf.azioni.forEach((action)=>{
								if (action.slug == "tutti-i-progetti") return
								$('#project_detail .header .info .text .actions').append(`<span>${action.name}</span><br>`)
							})
							$('#project_detail .header .image').attr('src', proj_data.acf.immagini_header_progetto.immagine_1.url)
							$('#project_detail .header .image_2').attr('src', proj_data.acf.immagini_header_progetto.immagine_2.url)
							$('#project_detail .header .description .image_3').attr('src', proj_data.acf.immagini_header_progetto.immagine_3.url)
							$('#project_detail .header .description .text').html(proj_data.content.rendered)
							
							$('#project_detail #video_section').removeClass('active')
							if (proj_data.acf.sezione_video_progetto.attiva) {
								$('#project_detail #video_section').addClass('active')
								$('#project_detail #video_section .section_title').text(proj_data.acf.sezione_video_progetto.titolo)
								let media = proj_data.acf.sezione_video_progetto.video.type == 'video' ?
								`<video class="media" src="${proj_data.acf.sezione_video_progetto.video.url}" autoplay muted></video>` :
								`<img class="media" src="${proj_data.acf.sezione_video_progetto.video.url}"/>`
								$('#project_detail #video_section .media_content').html(media)
							}

							$('#project_detail #image_section').removeClass('active')
							if (proj_data.acf.sezione_immagine_progetto.attiva) {
								$('#project_detail #image_section').addClass('active')
								$('#project_detail #image_section .image').attr('src', proj_data.acf.sezione_immagine_progetto.immagine.url)
							}

							$('#project_detail #image_text_section').removeClass('active')
							if (proj_data.acf.sezione_immagine_testo_progetto.attiva) {
								$('#project_detail #image_text_section').addClass('active')
								$('#project_detail #image_text_section .section_title').text(proj_data.acf.sezione_immagine_testo_progetto.titolo)
								$('#project_detail #image_text_section .image').attr('src', proj_data.acf.sezione_immagine_testo_progetto.immagine.url)
								$('#project_detail #image_text_section .text').html(proj_data.acf.sezione_immagine_testo_progetto.testo)
							}

							$('#project_detail #text_image_section').removeClass('active')
							if (proj_data.acf.sezione_immagine_testo_progetto.attiva) {
								$('#project_detail #text_image_section').addClass('active')
								$('#project_detail #text_image_section .section_title').text(proj_data.acf.sezione_testo_immagine_progetto.titolo)
								$('#project_detail #text_image_section .text').html(proj_data.acf.sezione_testo_immagine_progetto.testo)
								$('#project_detail #text_image_section .image').attr('src', proj_data.acf.sezione_testo_immagine_progetto.immagine.url)
							}
						}

						function openDetailPage(el, tl) {
							$('#project_detail').addClass('active')
							$(document.body).addClass('stop-scroll')
							$(`#${el.id} .image-container`).removeClass('active')

							tl.pause()
							tl.progress(0)

							$(window).scrollTop($('#project_template').offset().top);
							$(document).scrollTop($('#project_template').offset().top);

							ScrollTrigger.create({
								trigger: '#project_detail .end_helper',
								scroller: '#project_detail',
								start: 'bottom-=5px bottom',
								onEnter() {
									project_clicked = false
									$('#project_detail').scrollTop(0);
									$('#project_detail').removeClass('active')
									$(document.body).removeClass('stop-scroll')
								}
							})

							$(window).on('beforeunload', function() {
								$('#project_detail').scrollTop(0);
							});
						}
					}
				}
			}
		});
	}
	
    function handleEnter(container, gif) {
        // Applica il blur a 0 a tutti i container
        gsap.to(container, {filter: "brightness(1)", duration: 0.1});
        
        // Se il container ha una GIF, cambia l'opacità da 0 a 1
		if (gif) {
			gsap.to(gif, {opacity: 1, filter: "brightness(1)", duration: 0.1});
			// Nasconde l'immagine sottostante
			container.querySelector('img').classList.add('hidden');
		}
    }

    function handleLeave(container, gif) {
        // Applica il blur a tutti i container
        gsap.to(container, {filter: "brightness(0.5)", duration: 0.1});
        
    // Se il container ha una GIF, cambia l'opacità da 1 a 0 e mostra l'immagine sottostante
    if (gif) {
        gsap.to(gif, {opacity: 0, filter: "brightness(0.5)", duration: 0.1});
        // Mostra l'immagine sottostante
        container.querySelector('img').classList.remove('hidden');
    }
    }

	// Carica i progetti quando la pagina viene caricata
	// Se è passato via post un termine carica i progetti specifici
	var term = jQuery('.cta_azioni.active .text_bottom').text();
	
	if (term) {
		loadProjects(term);
	} else {
		loadProjects('Tutti i progetti');
	}

	jQuery('.cta_azioni').click(function(e) {
		e.preventDefault();

		// Rimuovi la classe 'active' da tutti i bottoni
		jQuery('.cta_azioni').removeClass('active'); 

		// Aggiungi la classe 'active' al bottone cliccato
		jQuery(this).addClass('active');

		var term = jQuery('.cta_azioni.active .text_bottom').text();

		// Carica i progetti quando si fa clic su un bottone
		loadProjects(term);
	});
});

jQuery(document).ready(function($) {
	var offset = 0; // Inizializza l'offset a 0

	// Carica i post quando la pagina viene caricata
	loadPosts('tutti gli articoli', offset, '');

	$('#search-form').submit(function(e) {
		e.preventDefault();
		var search = $('#search-field').val();
		loadPosts('tutti gli articoli', 0, search);
	});

	$('.cta_azioni_articoli').click(function(e) {
		e.preventDefault();

		// Rimuovi la classe 'active' da tutti i pulsanti
		$('.cta_azioni_articoli').removeClass('active');

		// Aggiungi la classe 'active' al pulsante cliccato
		$(this).addClass('active');

		var term = $(this).text();

		// Azzera l'offset quando si cambia termine
		offset = 0;

		// Carica i post quando viene cliccato un pulsante
		loadPosts(term, offset, '');
	});

	$('#load-more').click(function(e) {
		e.preventDefault();

		// Incrementa l'offset quando si clicca su "Carica altro"
		offset += 6;

		var term = $('.cta_azioni_articoli.active').text();
		var search = $('#search-field').val();

		// Carica pi? post quando viene cliccato "Carica altro"
		loadPosts(term, offset, search);
	});

	function loadPosts(term, offset, search) {
		var data = {
			'action': 'filter_posts',
			'term': term,
			'offset': offset,
			'search': search
		};

		$.post(handle_ajax_vars.ajaxurl, data, function(response) {
			// Se l'offset 蠰, sostituisci i post esistenti
			// Altrimenti, aggiungi i nuovi post alla fine
			if (offset === 0) {  
				$('#article_container').html(response); 
			} else {
				$('#article_container').append(response);
			}
		});
	}
});

function loadPosts(term, offset, search) {
	var data = {
		'action': 'filter_posts',
		'term': term,
		'offset': offset,
		'search': search
	};

	$.post(handle_ajax_vars.ajaxurl, data, function(response) {
		// Svuota il contenuto della colonna
		$('#col-8').empty();

		// Aggiungi i nuovi post alla colonna
		$('#col-8').html(response);
	});
}

async function getProjectById (id) {
  return await (await fetch(`/wp-json/wp/v2/progetto/${id}?_fields[]=acf&_fields[]=title&_fields[]=content&acf_format=standard`)).json()
}

async function test (id) {
  console.log(await (await fetch(`/wp-json/wp/v2/progetto/${id}?acf_format=standard`)).json())
}

test(721)